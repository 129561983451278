import React from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import config from '../../../config'
import { mediaMax } from '../../constants/breakpoints'

export interface ErrorProps {
  text?: string
}

const Error = ({ text }: ErrorProps) => {
  const { t } = useTranslation('common')
  return (
    <Container>
      <BackgroundImage />
      <BackgroundGradient />
      <TextWrapper>
        <Text>{text || t('pagenotfound')}</Text>
      </TextWrapper>
    </Container>
  )
}

export default Error

const BackgroundGradient = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 80%);
`
const BackgroundImage = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: ${({ theme }) => theme.colors.primary.base};
  background-size: cover;
`
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: black;
  overflow: auto;
  justify-content: center;
  display: none;
  background: black;
  z-index: 10;

  display: flex;
  &.show {
    display: flex;
  }

  @media ${mediaMax.mobileL} {
    padding: 0 20px;
  }
`
const Text = styled.p`
  font-style: normal;
  font-weight: bold;
  color: white;

  font-size: max(2.91666vw, 42px);
  line-height: 161.905%;
  padding-right: 3.54167vw;
  /* or 162% */
`
const TextWrapper = styled.div`
  text-align: center;
`
