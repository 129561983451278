/* eslint-disable import/no-cycle */
import React from 'react'
import styled from 'styled-components'
import { ButtonCommon, ButtonCommonProps } from './components/ButtonCommon'
import { ButtonCustom, ButtonCustomProps } from './components/ButtonCustom'
import { ButtonLiveEvents } from './components/ButtonLiveEvents'
import { ExpandableButtonLocale } from './components/ExpandableButtonLocale'
import { TheExpandableButton } from '../../common/TheExpandableButton'
import { mediaMax } from '../../constants/breakpoints'
import { useMenuDispatch, useMenuState } from '../../providers/menuProvider'
import { Event } from '../../types/viewModels'
import SvgUser from '../Svg/User'
import UserMenu from '../UserMenu'
import config from '../../../config'

export interface BreadCrumb {
  title: string,
  link?: string
}

type HeaderButtonBase = {
  key: number | string
  placement?: 'left' | 'right'
}

type HeaderButton = HeaderButtonBase & (
  | ({ variant: 'custom' } & ButtonCustomProps)
  | ButtonCommonProps
)
export type HeaderButtons = Array<HeaderButton>

const defaultButtons: HeaderButtons = [
  { key: 1, variant: 'menu' },
  { key: 2, variant: 'home' },
  // { key: 3, variant: 'contact' },
]

export type HeaderProps = {
  buttons?: HeaderButtons
  breadCrumbs?: Array<BreadCrumb>,
  liveEvents?: Array<Event>
  isAuthenticated?: boolean
}
export const Header: React.FC<HeaderProps> = ({
  buttons = defaultButtons,
  breadCrumbs,
  liveEvents,
  isAuthenticated = true,
}: HeaderProps) => {
  const menuState = useMenuState()
  const menuDispatch = useMenuDispatch()

  const resolveHeaderButton = ({ ...rest }: HeaderButton) => {
    if (rest.variant === 'custom') {
      const { ...props } = rest
      return <ButtonCustom {...props} />
    }
    return <ButtonCommon variant={rest.variant} />
  }

  return (
    <Container>
      <LeftNavigation>
        {buttons
          .filter((button) => button.placement !== 'right')
          .map((button) => (
            <React.Fragment key={button.key}>
              {resolveHeaderButton(button)}
            </React.Fragment>
          ))}

        {config.USE_LANGUAGES && <ExpandableButtonLocale />}
      </LeftNavigation>

      {/* {(breadCrumbs && menuState.menu === 'burger') && <BreadCrumbs crumbs={breadCrumbs} />} */}

      <Spacer />

      <RightNavigation>
        {liveEvents && (<ButtonLiveEvents events={liveEvents} />)}
        {isAuthenticated && (<TheExpandableButton onClick={() => menuDispatch({ type: 'USER_MENU_TOGGLE' })} hoverMaxWidth="250px" hoverWidth="19.1vw" buttonIcon={<SvgUser height="max(1.52vw, 25px)" width="max(1.52vw, 25px)" />} style={{zIndex:100}}>
          <UserMenu/>
        </TheExpandableButton>)}
        {buttons
          .filter((button) => button.placement === 'right')
          .map((button) => (
            <React.Fragment key={button.key}>
              {resolveHeaderButton(button)}
            </React.Fragment>
          ))}
      </RightNavigation>
    </Container>
  )
}

const Container = styled.header`
  position: fixed;
  left: 0;
  z-index: 400;
  width: 100%;
  top: 30px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  @media ${mediaMax.mobileL}{
    top: 15px;
    padding: 0 15px;
  }
`

const LeftNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  pointer-events: auto;

  button:not(:first-child), a:not(:first-child) {
    margin-top: max(10px, 0.69444444vw);
  }
  @media ${mediaMax.tablet} {
    flex-direction: row;

    button:not(:first-child), a:not(:first-child) {
      margin-left: 10px;
      margin-top: 0;
    }

    .button {
      margin-right: 15px;
      margin-top: 0;
      transition: margin-right 0.5s;

      &:not(:first-child) {
        margin-top: 0;
      }
      &.active {
        margin-right: 65px;
      }
    }
  }
`

const RightNavigation = styled.nav`
  display: flex;
  pointer-events: auto;
  button:nth-child(2) {
    margin-left: 10px;
  }
`

const Spacer = styled.div`
  flex: 1;
`
