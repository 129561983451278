import { BoxProps } from './src/components/Menu/Menu'

const ROUTES = {
  live: '/live',
  program: '/program',
  event: '/event',
  showroom: '/showrooms',
  subroom: '/room',
  favorites: '/favorites',
}

// General menus:

// FOR A NEW MENU FOR A DIFFERENT ROLE, MAKE ANOTHER MENU LIKE THIS ONE
const menuGeneral: Array<BoxProps> = [
  {
    label: 'Bein útsending',
    order: 1,
    background: '#D00010',
    gridArea: '1 / 1 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href: `${ROUTES.live}/reykjavik`,
  },
  {
    label: 'Dagskrá',
    order: 2,
    background: '#ff6773',
    gridArea: '1 / 17 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href:`${ROUTES.program}/reykjavik`,
  },
  // {
  //   label: 'Sýningarbásar',
  //   order: 3,
  //   background: '#40577A',
  //   gridArea: '17 / 17 / span 16 / span 16',
  //   backgroundScaleFrom: 'top',
  //   labelFontVariant: 'xl',
  //   href: ROUTES.showroom,
  // },
]

const menuGeneralEN: Array<BoxProps> = [
  {
    label: 'Live webinar',
    order: 1,
    background: '#25b69b',
    gridArea: '1 / 1 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href: `${ROUTES.live}/webinar`,
  },
  {
    label: 'Programme', 
    order: 2,
    background: '#1B6A93',
    gridArea: '1 / 17 / span 32 / span 16',
    backgroundScaleFrom: 'top',
    labelFontVariant: 'xl',
    href:`${ROUTES.program}/webinar`,
  },
  // {
  //   label: 'Showrooms',
  //   order: 3,
  //   background: '#40577A',
  //   gridArea: '17 / 17 / span 16 / span 16',
  //   backgroundScaleFrom: 'top',
  //   labelFontVariant: 'xl',
  //   href: ROUTES.showroom,
  // },
]

/* eslint-disable */
export default {
  // Lisa
  LISA_modelId_speakers: 'b2d77074-1694-442f-fd87-08d981b73b16',
  LISA_modelId_streams: '23a9a23a-8559-4e22-fd89-08d981b73b16',
  LISA_modelId_frontPage: '4510df23-4b5d-4ba1-fd86-08d981b73b16',
  LISA_modelId_fakelogin: '111',
  LISA_modelId_registrations: '3801281d-37be-49fa-fd8a-08d981b73b16',
  LISA_modelId_loginLogs: '7ca9b06d-a998-4d78-fd8b-08d981b73b16',
  LISA_modelId_contact: '79af40d8-f7cb-4396-fd8c-08d981b73b16',
  LISA_modelId_expo: '222',
  LISA_modelId_WindowOutbound: '333',
  LISA_modelId_WindowInbound: '444',
  LISA_modelId_WindowVideo: '555',
  LISA_modelID_overviewRooms: '666',

  LISA_modelID_allLocations: 'a912ccb4-4008-4a7e-fd8d-08d981b73b16',
  LISA_modelId_workshoplink: '777',
  LISA_modelId_workshopProgram: '888',
  
  LISA_API_URL: 'https://nextjs-kom.azurewebsites.net/api',
  LISA_IMAGE_BASE: 'https://nextjs-kom.azurewebsites.net/library?itemid=',

  /* CONTACT INFORMATION */
  CONTACT_email_subject: 'Fyrirspurn frá KOM',
  CONTACT_email_sender_name: 'Advania',
  CONTACT_receiver: 'groundfish@groundfishforum.com',
  CONTACT_sender_name: 'KOM',

  // Custom styles
  BACKGROUND_image_lobby: '/Forsidumyndir-bla-min.jpg',
  BACKGROUND_image_live: '/eyjar.jpg',
  BACKGROUND_image_event: '/djupivogur.jpg',
  BACKGROUND_image_program: '/greenland2.jpg',
  MOBILE_IMAGE_SYNINGARRYMI: '/greenland2.jpg',

  EXPO_WALLPAPER: {
    Top: '/harpa-loft.png', // "/tileTop.jpg",
    TwilioTop: '/flekar_workshop-02.png', // "/tileTop.jpg",
    Inner: '/harpa-wall.jpg', // "/TileInner.png",
    Floor: '/harpa-floor.jpg', // "/TileFloor.png",
    LeftRight: '/harpa-wall.jpg', // "/TileLeftRight.png"
  },

  // LOGO: '/1366x768_Glow.png',
  LOGO: '/gff-logo-transparent.png',
  LOGO_EN: '/gff-logo-transparent.png',

  PROJECT_NAME_SEO: 'Groundfish forum',
  PROJECT_URL_SEO: 'kom.advania.is',
  PROJECT_IMAGE_SEO: '/IGF-soc-med-pic.jpg',

  SPONSORS_BACKGROUND: '/Bakgrunnur_sponsor.png',

  FALLBACK_PERSON_IMAGE: '/sample_user.png',

  // Options are : FAKE, LISA
  LOGIN_TYPE: 'LISA',

  // ARE THERE MULTIPLE LINES OF PROGRAMMING OG LIVE STREAMING, IF TRUE, DISPLAYS NAME OF EACH LINE IN PROGRAM TITLE
  MUTLIPLE_PROGRAMS: false,
  // MENU -> IF WE HAVE MORE THAN ONE ROLE.
  // CREATE E.G MENU_ANOTHERROLE : menu_anotherrole
  // THIS THEN NEEDS TO BE CONNECTED IN _APP.TSX
  
  BASIC_ROUTES: ROUTES,

  USE_FAVORITES: false,
  USE_TWILIO: false,
  USE_SHOWROOMS:false,
  USE_LANGUAGES: false,
  
  // After opening the conference:
  MENU_GENERAL: menuGeneral,
  MENU_GENERAL_EN: menuGeneralEN,
  
  // Before opening the conference:
  // MENU_GENERAL: menuProgramSub,
  // MENU_GENERAL_EN: menuProgramSubEN,
  // MENU_GENERAL_SE: menuProgramSubSE,

  LOBBY_MENU_PROGRAMS: [],
  LOBBY_MENU_PROGRAMS_EN: [],

  LOBBY_MENU_ROOMS: [
    {
      link: `${ROUTES.live}/webinar`,
      title: 'Bein útsending - Reykjavík',
      openInNewTab: false,
      authAllowed: ['general'],
    },
    {
      link: `${ROUTES.program}/webinar`,
      title: 'Reykjavík',
      openInNewTab: false,
      authAllowed: ['general'],
    },
    // {
    //   link: ROUTES.showroom,
    //   title: 'Sýningarbásar',
    //   openInNewTab: false,
    //   authAllowed: ['general'],
    // },
  ],

  LOBBY_MENU_ROOMS_EN: [
    {
      link: `${ROUTES.live}/webinar`,
      title: 'Live webinar',
      openInNewTab: false,
      authAllowed: ['general'],
    },
    {
      link: `${ROUTES.program}/webinar`,
      title: 'Programme',
      openInNewTab: false,
      authAllowed: ['general'],
    },
    // {
    //   link: ROUTES.showroom,
    //   title: 'Showrooms',
    //   openInNewTab: false,
    //   authAllowed: ['general'],
    // },
  ],
  // Options are : IS, EN, SE
  LANGUAGE: 'IS',
}
