const theme = {
  colors: {
    black: '#303030',
    white: '#fff',
    warning: '#F03434',
    grayLight: '#f3f3f3',
    error: '#F03434',

    // TODO: Find a better color.
    success: 'green',

    primary: {
      base: '#037F91',
      light: '#5FCDE3',
      dark: '#209DB6',
    },

    secondary: {
      base: '#0092AC',
      light: '#BB23AD',
      dark: '#510F4B',
    },

    third: {
      base: '#40577A',
      light: '#5FCDE3',
      dark: '#6E8D15',
    },

    fourth: {
      base: '#F38901',
      light: '#FE9E1E',
      dark: '#E18101',
    },

    fifth: {
      base: '#30302F',
      light: '#696967',
      dark: '#171717',
    },
  },

  fonts: {
    // TODO: We need to add some fallback fonts.
    fontFamilyPrimary: 'Merriweather',
    fontFamilySecondary: 'OpenSans',
  },
}

export type ThemeType = typeof theme
export { theme }
