/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import { EnumRoles } from './roles'
import config from '../../../config'

export { checkAuth } from './checkAuth'
export { EnumRoles } from './roles'
/*
  TO ADD A ROLE DO THIS:
    1. ADD THE ROLE TO THE EnulRoles type
    2. ADD A NEW LIST TO LISA UNDER REGISTRATION WHERE USERS IN THIS NEW ROLE ARE LISTED
    3. ADD THE ID OF THE NEW LISA MODEL LIST TO CONFIG
    4. GET A LIST OF REGISTERED USERS FROM NEW LIST BELOW:
        * const queryResults_general = queryResults[0] LINE:64 -> const querylist_newrole = queryResults[1]
    5. CHECK IF THE EMAIL IS IN THE NEW LIST ->  if(listContainsEmail(queryResults_newrole, email)) {
        return { role: EnumRoles.NEWROLE }
      }
    6. YOU ARE DONE - YAYYY
*/

export type User = {
  id?: string
  email: string
  role: EnumRoles
  name?: string
  company?: string
  numberOfLogins?: number
  registrationDocumentId?: string
} | null

export type LisaUser = {
  email: string
  name?: string
  company?: string
  numberOfLogins?: number
  registrationDocumentId?: string
} 

const listContainsEmail = (list: LisaUser[], email: string): boolean => {
  const lowercaseEmail = email.toLowerCase()
  for (let i = 0; i < list.length; i++) {
    if (lowercaseEmail === list[i].email.toLowerCase()) {
      return true
    }
  }
  return false
}

type LISADocumentRegister = { id: string, fieldValues: { email: string, name: string, company: string, numberOfLogins?: number } }

enum EnumFetchEmailRegisteredRoleError {
  General = 'general',
  NotRegistered = 'notRegistered',
}

export type FetchEmailRegisteredRole = {
  role: EnumRoles
  error: null
  user: LisaUser
} | {
  role: null
  error: EnumFetchEmailRegisteredRoleError
  user: null
}
export const fetchEmailRegisteredRole = async (email: string):Promise<FetchEmailRegisteredRole> => {
  if (config.LISA_API_URL === undefined) {
    throw new Error('config.LISA_API_URL is undefined')
  }
  if (process.env.LISA_API_KEY === undefined) {
    throw new Error('process.env.LISA_API_KEY is undefined')
  }

  const endpoint = `${config.LISA_API_URL}/documents/Documents`  
  const queryGeneral = `/query?modelId=${config.LISA_modelId_registrations}&search=${email}`
  
  const fetchQuery = (url: string) => fetch(
    url,
    {
      headers: {
        Authorization: `Bearer ${process.env.LISA_API_KEY}`,
      },
    },
  )

  const fetchQueries = [
    fetchQuery(`${endpoint}${queryGeneral}`),
  ]

  const responses = await Promise.all(fetchQueries)

  let data = []
  try {
    data = await Promise.all(responses.map((res) => {
      if (!res.ok) {
        throw new Error(`${endpoint}${queryGeneral}`)
      }
      return res.json()
    }))
  }
  catch (error) {
    console.log('error', error)
    return {
      error: EnumFetchEmailRegisteredRoleError.General,
      role: null,
      user: null,
    }
  }

  const queryResults = data.map((subdata) => {
    const lisausers: LisaUser[] = subdata.documents.map((document: LISADocumentRegister) => {
      const lisauser: LisaUser = {
        email: document.fieldValues.email,
        company: document.fieldValues.company,
        name: document.fieldValues.name,
        numberOfLogins: document.fieldValues.numberOfLogins,
        registrationDocumentId: document.id,
      }

      return lisauser
    })

    return lisausers
  })


  for (let i = 0; i < queryResults.length; i++) {
    const queryResult = queryResults[i]
    if (listContainsEmail(queryResult, email)) {
      const role = EnumRoles.General
      const founduser: LisaUser = queryResult.filter(item => item.email === email)[0]
      return { role, error: null, user: founduser }
    }
  }

  return {
    error: EnumFetchEmailRegisteredRoleError.NotRegistered,
    role: null,
    user: null,
  }
}

export const countLogin = async (user: User):Promise<void> => {
  const endpoint = `${config.LISA_API_URL}/documents/documents/${user.registrationDocumentId}/Values`

  const document = {
    hasLoggedIn: 'true',
    numberOfLogins: user.numberOfLogins + 1,
  }

  let response: Response
  try {
    response = await fetch(`${endpoint}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${process.env.LISA_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(document),
    })
  }
  catch (error) {
    console.error('countlogin - ERROR')
    console.error(error)
    return
  }

  if (response.ok) {
    console.log('countlogin - SUCCESS')
  }
  else {
    console.error('countlogin - ERROR')
    console.error({ status: response.status, statusText: response.statusText })
  }
} 

export const logLogin = async (email: string, role?: EnumRoles):Promise<void> => {
  const modelId = config.LISA_modelId_loginLogs

  const endpoint = `${config.LISA_API_URL}/documents/documents/createdocument`

  const document = {
    email,
    role,
    registerAt: new Date().toUTCString(),
  }

  let response: Response
  try {
    response = await fetch(`${endpoint}?modelId=${modelId}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.LISA_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(document),
    })
  }
  catch (error) {
    console.error('logLogin - ERROR')
    console.error(error)
    return
  }

  if (response.ok) {
    console.log('logLogin - SUCCESS')
  }
  else {
    console.error('logLogin - ERROR')
    console.error({ status: response.status, statusText: response.statusText })
  }
}
