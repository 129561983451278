import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useWhoAmI } from '../../utils/hooks/useWhoAmI'

const UserMenu = () => {
  const { t } = useTranslation('common')
  const user = useWhoAmI()

  return(
    <UserMenuContainer>
      <UserInfo>
        <UserMenuName>{user?.user?.name}</UserMenuName>
        <UserMenuCompany>{user?.user?.company}</UserMenuCompany>
      </UserInfo>
      <UserLinks>
        {/* <Link href="/favorites" passHref><UserLink>{t('myProgram')}</UserLink></Link> */}
        <Link href="/logout" passHref><UserLink>{t('logout')}</UserLink></Link>
      </UserLinks>
    </UserMenuContainer>
  )
}

export default UserMenu

const UserMenuContainer = styled.div`
  padding: max(20px, 1.52vw);
  padding-bottom:0;
  display:flex;
  flex-direction:column;
  align-items: flex-start;
`

const UserMenuName = styled.h2`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-weight: 700;
  font-size: max(1.1vw, 14px);
  line-height: 150%;
  text-align:left;
`
const UserMenuCompany = styled.h3`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-weight: 400;
  font-size: max(1.1vw, 14px);
  line-height: 150%;
  text-align:left;
`

const UserInfo = styled.div`
  margin-bottom:20px;
`

const UserLinks = styled.div`
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  width: 100%;
`

const UserLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: max(1.1vw, 14px);
  height: max(40px, 3.125vw);
  width: 100%;
  line-height: max(40px, 3.125vw);
  text-align:left;
  display:block;
  border-top: 1px solid #E5E5E5;
`