import { createElement, useEffect, useRef } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { mediaMax, mediaMin } from '../constants/breakpoints'
import { useMenuState } from '../providers/menuProvider'

export interface TheExpandableButtonProps {
  style?: React.CSSProperties
  className?: string
  active?: boolean
  children: React.ReactNode
  buttonTitle?: string
  buttonIcon?: React.ReactNode
  hoverWidth?: string
  hoverMaxWidth?: string
  onClick?: () => void
}

export const TheExpandableButton: React.FC<TheExpandableButtonProps> = ({
  style,
  className,
  children,
  buttonTitle,
  active,
  buttonIcon,
  hoverMaxWidth,
  hoverWidth,
  onClick,
}) => {
  // For the hover animation we need the button unexpanded width.
  const refButton = useRef<HTMLButtonElement>(null)
  const refExpandingBox = useRef<HTMLDivElement>(null)

  const menuState = useMenuState()

  useEffect(() => {
    const resize = () => {
      if (refButton.current === null) {
        return
      }
      if (refExpandingBox.current === null) {
        return
      }

      const width = refButton.current.clientWidth
      refExpandingBox.current.style.width = `${width}px`
    }

    resize() // Call it once.
    window.addEventListener('resize', resize) // Call it maybe more then once.

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <Container
      ref={refButton}
      style={style}
      className={classnames(className, { active }, { hidden : menuState.menu !== 'burger' })}
      onClick={onClick}
    >
      <ButtonContent>
        {buttonIcon || buttonTitle}
      </ButtonContent>

      <HoverContent hoverMaxWidth={hoverMaxWidth} hoverWidth={hoverWidth}>
        <ExpandingBox ref={refExpandingBox} />
        <HoverSubContent>
          {children}
        </HoverSubContent>
      </HoverContent>
    </Container>
  )
}

const ButtonContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;

  transition-delay: 300ms;
  transition-duration: 100ms;
  transition-property: opacity;
`

const ExpandingBox = styled.div`
  position: absolute;
  height: max(45px, 3.125vw);
  border-radius: max(22.5px,1.5625vw);
  background-color: ${({ theme }) => theme.colors.white};
  top: 0;
  right: 0;
  transition-timing-function: cubic-bezier(.19,1,.22,1);
  transition-duration: 300ms;
  transition-delay: 200ms;
`

const HoverContent = styled.div<TheExpandableButtonProps>`
  position: absolute;
  right: 0;
  top: 0;
  width: ${({ hoverWidth, hoverMaxWidth }) => `max(${hoverMaxWidth}, ${hoverWidth})`};
  pointer-events: none;

`

const HoverSubContent = styled.div`
  padding-bottom: 0;
  opacity: 0;
  position: relative;
  z-index: 10;

  transition-duration: 200ms;
  transition-property: opacity;
`

// This should be a button element.
const Container = styled.button`
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    position: relative;

    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

    border-radius: max(22.5px,1.5625vw);
    height: max(45px, 3.125vw);
    min-width: max(45px, 3.125vw);

    &.hidden{
      display:none;
    }

    @media ${mediaMin.mobileL}{

      &:hover, &.active {
        ${ButtonContent} {
          transition-delay: 0s;
          opacity: 0;
        }

        ${HoverContent} {
          pointer-events: auto;

            ${ExpandingBox} {
              transition-delay: 0s;
              width: 100% !important;
              height: 100%;
            }
      
            ${HoverSubContent} {
              opacity: 1;
              transition-delay: 200ms;
            }
        }

      }
    }
`

const Text = styled.span`
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-weight: bold;
  letter-spacing: 0;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  color: ${({ theme }) => theme.colors.black};
  font-size: max(13px, 0.9vw);
  padding-right: max(20px, 1.2vw);
`
